import Vue from 'vue'
import Store from './../store'
// import {data_get,data_set} from './../helpers/funciones'

export default {
	getEnv: function() {
		return process.env.VUE_APP_ENV;
	}
	,normalizar: function(text) {
		if (text) {
			text = text.toLowerCase();
	    	text = text.split('á').join('a');
	    	text = text.split('é').join('e');
	    	text = text.split('í').join('i');
	    	text = text.split('ó').join('o');
	    	text = text.split('ú').join('u');
	    	text = text.split('ñ').join('n');
	    	text = text.split(' ').join('_');
	    }

    	return text;
	}

	,url_options: function(url, options, display_frontend = false) {
		if (options && options.filters)
			Vue.prototype.$log.info('options', options.filters.length);

		let full_url = url;
		let params_url = '';

		if (options) {
			display_frontend=true;
			if (options.page)
				params_url+= '&page='+options.page;
			if (options.order_col)
				params_url+= '&order_col='+options.order_col
			if (options.order_dir)
				params_url+= '&order_dir='+options.order_dir
			if (options.limit)
				params_url+= '&limit='+options.limit;
			if (options.num_items)
				params_url+= '&num_items='+options.num_items;
			if (options.search)
				params_url+= '&search='+encodeURI(options.search);

			if (options.filters && options.filters.length > 0) {
				let numFilter = 0;
				options.filters.forEach(function(item) {
					let filter = '&f['+numFilter+'][relation]='+encodeURI(item.relacion);
					filter += '&f['+numFilter+'][column]='+encodeURI(item.columna);
					filter += '&f['+numFilter+'][operator]='+encodeURI(item.operador);
					filter += '&f['+numFilter+'][query_1]='+encodeURI(item.valor1);
					filter += item.valor2 !== null && typeof item.valor2 != 'undefined' ? '&f['+numFilter+'][query_2]='+encodeURI(item.valor2) : '';

					params_url+= filter;
					numFilter++;
				});
			}
		}

		if (display_frontend) {
			full_url += '?display=frontend'
			full_url += params_url.length ? params_url : ''
		}else
			full_url += params_url.length ? '?'+params_url.substring(1,params_url.length) : ''

		return full_url;
	}

	,showAxiosError: function(err, title, config) {
		Vue.prototype.$log.info('err', err.response);
		let errorMessage = err.toString();

		if (errorMessage.indexOf('Error:') != -1)
			errorMessage = errorMessage.substr(7);

		if (err.response && err.response.data && err.response.data.message)
			errorMessage = err.response.data.message;

		if (err.response && err.response.data && err.response.data.error) {
			errorMessage += '<ul>';
			if (typeof(err.response.data.error) != 'string')
				Object.keys(err.response.data.error).forEach(key => {
					errorMessage += '<li>'+err.response.data.error[key]+'</li>';
				})
			else
				errorMessage += '<li>'+err.response.data.error+'</li>';

			errorMessage += '</ul>';
		}

		if (err.response && err.response.data && err.response.data.errors) {
			errorMessage += '<ul>';
			Object.keys(err.response.data.errors).forEach(key => {
				errorMessage += '<li>'+err.response.data.errors[key]+'</li>';
			})
			errorMessage += '</ul>';
		}

		if (typeof(config) == 'undefined')
			config = {
				group: 'alert'
				,type: 'error'
				,title: title
				,duration: 15000
			};

		if (!config.group)
			config.group = 'alert';

		if (!config.type)
			config.type = 'error';

		if (!config.duration)
			config.duration = 15000;

		config.text = errorMessage;

		Vue.prototype.$notify(config);
	}

	,showMessage: function(titulo, mensaje, tipo, grupo, duracion) {
		let payload = null;

		if (typeof(tipo) == 'string' || typeof(tipo) == 'undefined') {
			if (!tipo)
				tipo = 'success'

			if (!grupo)
				grupo = 'notifications';

			if (!duracion)
				duracion = 10000;

			payload = {
				group: grupo
				,type: tipo
				,title: titulo
				,text: mensaje
				,duration: duracion
			};
		}else {
			payload = {
				type: tipo.tipo ? tipo.tipo : 'success'
				,group: tipo.grupo ? tipo.grupo : 'notifications'
				,duration: tipo.duracion ? tipo.duracion : 10000
				,width: tipo.ancho ? tipo.ancho : 300
				,title: titulo
				,text: mensaje
			}
		}

		Vue.prototype.$notify(payload);
	}

	,toJson: function(data) {
		return encodeURI(JSON.stringify(data));
	}

	,fromJson: function(data) {
		return JSON.parse(decodeURI(data));
	}

	,bytesConverted: function(size) {
		size = parseInt(size);

		let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (size == 0) return '0 Byte';
		let i = parseInt(Math.floor(Math.log(size) / Math.log(1024)));
		return Math.round(size / Math.pow(1024, i), 2) + ' ' + sizes[i];
	}

	,strRand: function(largo, type, char_manual) {
		if (!largo)
			largo = 8;

		if (!type)
			type = 'alfa';

		let str = '';

		if (typeof char_manual == 'undefined')
			if (type == 'alfa') {
				let char = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJXKMNOPQRSTUVWXYZ0123456789';
				let totalChar = char.length;

				for(let i=0; i<largo; i++) {
					str += char.charAt(Math.floor(Math.random()*totalChar));
		 		}
		 	}else if (type == 'numeric') {
		 		let char = '0123456789';
				let totalChar = char.length;

				for(let i=0; i<largo; i++) {
					str += char.charAt(Math.floor(Math.random()*totalChar));
		 		}
		 	}else {
		 		let char = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJXKMNOPQRSTUVWXYZ';
				let totalChar = char.length;

				for(let i=0; i<largo; i++) {
					str += char.charAt(Math.floor(Math.random()*totalChar));
		 		}
		 	}
		else {
			let totalChar = char_manual.length;

			for(let i=0; i<largo; i++) {
				str += char_manual.charAt(Math.floor(Math.random()*totalChar));
	 		}
		}

	 	return str;
	}
	,getUrlParam: function(param, defValue) {
		let rawParams = window.location.search;
		rawParams = rawParams.substr(1);
		rawParams = rawParams.split('&');

		let params = new Object();
		for(let i=0; i<rawParams.length; i++) {
			let tmpParam = rawParams[i].split('=');
			params[tmpParam[0]] =  tmpParam[1];
		}

		return Object.keys(params).indexOf(param) != -1 ? params[param] : defValue;
	}
	,setUrlParam: function(key, value) {
		if (typeof(key) != 'string' && typeof(key) != 'number')
			throw new Error('la llave a agregar debe ser un string');

		if (typeof(value) != 'string' && typeof(value) != 'number')
			throw new Error('El valor a agregar debe ser un string');

		key = encodeURI(key);
		value = encodeURI(value);

		let rawParams = window.location.search;
		let params = new Object();

		if (rawParams != '') {
			rawParams = rawParams.substr(1);
			rawParams = rawParams.split('&');

			for(let i=0; i<rawParams.length; i++) {
				let tmpParam = rawParams[i].split('=');
				params[tmpParam[0]] =  tmpParam[1];
			}
		}

		params[key] = value;

		let uri = '?';

		let keys = Object.keys(params);
		for(let i=0;i<keys.length; i++) {
			if (uri != '?') uri += '&';

			uri += keys[i]+'='+params[keys[i]];
		}

		let refresh = window.location.protocol + "//" + window.location.host + window.location.pathname;
		refresh +=  uri;
		window.history.pushState({ path: refresh }, '', refresh);
	}

	,moneyFormat: function(amount, decimalCount = 2, decimal = ".", thousands = ",") {
	  try {
	    decimalCount = Math.abs(decimalCount);
	    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

	    const negativeSign = amount < 0 ? "-" : "";

	    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
	    let j = (i.length > 3) ? i.length % 3 : 0;

	    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
	  } catch (e) {
	    Vue.prototype.$log.info('error', e);
	  }
	}

	,clone: function(data) {
		return JSON.parse(JSON.stringify(data));
	}

	,clearFormRequest: function(data) {
		let clearData = Array.isArray(data) ? [] : {};

		Object.keys(data).forEach(key => {
			if (data[key] !== null)
				if (typeof(data[key]) != 'object')
					clearData[key] = data[key];
				else
					clearData[key] = this.clearFormRequest(data[key]);
		});

		return clearData;
	}

	,formDataBuilder: function(data, formdata, key) {
		if (!formdata)
			formdata = new FormData();

		if (!key)
			key = '';

		Object.keys(data).forEach(subkey => {
			if (data[subkey] !== null) {
				if (typeof(data[subkey]) != 'object')
					formdata.append((key != '' ? key+'['+subkey+']' : subkey), data[subkey]);
				else
					formdata = this.formDataBuilder(data[subkey], formdata, (key != '' ? key+'['+subkey+']' : subkey));
			}
		});

		return formdata;
	}

	,cache_store: async function(key, data) {
		key = 'apps.'+key;
		let value = this.data_get(Store.state, key);
		let cache_enabled = process.env.VUE_APP_ENABLE_CACHE;

		if (typeof cache_enabled != 'boolean')
			cache_enabled = true;

		if (cache_enabled) {
			if (!value) {
				if (typeof data != 'function')
					throw new Error('Data debe ser una funcion que retorne el valor a guardar');
				
				value = (await data());
				let tmp_store = this.clone(Store.state);
				tmp_store = this.data_set(tmp_store, key, value);

				Store.commit('set', tmp_store);
			}

			return value;
		}
		
		return (await data());
	}

	,cache_clear: function(key) {
		if (typeof key == 'undefined') {
			Store.state.apps = {};
		}else {
			key = 'apps.'+key;

			let tmp_state = this.clone(Store.state);
			let tmp_state_2 = this.data_set(tmp_state, key, null);
			Store.commit('set', tmp_state_2)
		}
	}

	,data_get: function(target, path, fallback) {
	  let segments = Array.isArray(path) ? path : path.split('.');
	  let [segment] = segments;

	  let find = target;

	  if (segment !== '*' && segments.length > 0) {
	    if (find[segment] === null || typeof find[segment] === 'undefined') {
	      find = typeof fallback === 'function' ? fallback() : fallback;
	    }
	    else {
	      find = this.data_get(find[segment], segments.slice(1), fallback);
	    }
	  }

	  else if (segment === '*') {
	    const partial = segments.slice(path.indexOf('*') + 1, path.length);

	    if (typeof find === 'object') {
	      find = Object.keys(find).reduce((build, property) => ({
	          ...build,
	          [property]: this.data_get(find[property], partial, fallback)
	        }),
	      {});
	    }
	    else {
	      find = this.data_get(find, partial, fallback);
	    }
	  }

	  if (typeof find === 'object') {
	    if (Object.keys(find).length > 0) {
	      const isArrayTransformable = Object.keys(find).every(index => index.match(/^(0|[1-9][0-9]*)$/));

	      return isArrayTransformable ? Object.values(find) : find;
	    }
	  } else {
	    return find;
	  }
	}

	,data_set: function(target, path, value, vuelta=0) {
		let keys = path.split('.');

		if (keys.length == 1) {
			if (!target[keys[0]])
				target[keys[0]] = {}

			target[keys[0]] = value
			return target;
		}else if (vuelta <= keys.length) {
			if (!target[keys[0]])
				target[keys[0]] = {};

			let nuevo_pat = '';
			for(let a=1; a<keys.length; a++) {
				nuevo_pat += nuevo_pat == '' ? keys[a] : '.'+keys[a];
			}

			target[keys[0]] = this.data_set(target[keys[0]], nuevo_pat, value, vuelta+1);
		}

		return target;
	}

	,clear_null_nodes: function(data, empty=false) {
		let tmp_data;

		if (Array.isArray(data)) {
			tmp_data = [];
			for(let i=0; i<data.length; i++) {
				if (!empty) {
					if (data[i] !== null && data[i] !== NaN) {
						if (Array.isArray(data[i])) {
							tmp_data[i] = this.clear_null_nodes(data[i]), empty;
						}else if (!Array.isArray(data[i]) && typeof data[i] == 'object') {
							tmp_data[i] = this.clear_null_nodes(data[i]), empty;
						}else {
							tmp_data[i] = data[i];
						}
					}
				}else {
					if (data[i] !== null && data[i] !== '' && data[i] !== NaN) {
						if (Array.isArray(data[i])) {
							tmp_data[i] = this.clear_null_nodes(data[i]), empty;
						}else if (!Array.isArray(data[i]) && typeof data[i] == 'object') {
							tmp_data[i] = this.clear_null_nodes(data[i]), empty;
						}else {
							if (data[i]) {
								tmp_data[i] = data[i];
							}
						}
					}
				}
			}
		}else {
			let keys = Object.keys(data);
			tmp_data = {};

			for(let i=0; i<keys.length; i++) {
				if (!empty) {
					if (data[keys[i]] !== null && data[keys[i]] !== NaN) {
						if (Array.isArray(data[keys[i]])) {
							tmp_data[keys[i]] = this.clear_null_nodes(data[keys[i]], empty);
						}else if (!Array.isArray(data[i]) && typeof data[keys[i]] == 'object') {
							tmp_data[keys[i]] = this.clear_null_nodes(data[keys[i]], empty);
						}else if (typeof data[keys[i]] == 'number') {
							if (!isNaN(data[keys[i]])) {
								tmp_data[keys[i]] = data[keys[i]];
							}
						}else {
							tmp_data[keys[i]] = data[keys[i]];
						}
					}
				}else {
					if (data[keys[i]] !== null && data[keys[i]] !== '' && data[keys[i]] !== NaN) {
						if (Array.isArray(data[keys[i]])) {
							tmp_data[keys[i]] = this.clear_null_nodes(data[keys[i]], empty);
						}else if (!Array.isArray(data[keys[i]]) && typeof data[keys[i]] == 'object') {
							tmp_data[keys[i]] = this.clear_null_nodes(data[keys[i]], empty);
						}else if (typeof data[keys[i]] == 'number') {
							if (!isNaN(data[keys[i]])) {
								tmp_data[keys[i]] = data[keys[i]];
							}
						}else {
							if (data[keys[i]]) {
								tmp_data[keys[i]] = data[keys[i]];
							}
						}
					}
				}
			}
		}

		return tmp_data;
	}

	,encode_utf8(data) {
		if (data !== null) {
			if (typeof data == 'object') {
				let keys = Object.keys(data);

				for(let i=0; i<keys.length; i++) {
					if (typeof data[keys[i]] == 'object' && data[keys[i]] !== null)
						data[keys[i]] = this.encode_utf8(data[keys[i]]);
					else if (Array.isArray(data[keys[i]]))
						data[keys[i]] = this.encode_utf8(data[keys[i]]);
					else if (data[keys[i]] !== null)
						data[keys[i]] = unescape(encodeURIComponent(data[keys[i]]));
				}
			}else if (Array.isArray(data) && data[keys[i]] !== null) {
				for(let i=0; i<data.length; i++) {
					if (typeof data[i] == 'object')
						data[i] = this.encode_utf8(data[i]);
					else if (Array.isArray(data[keys[i]]))
						data[i] = this.encode_utf8(data[i]);
					else if (data[i] !== null)
						data[i] = unescape(encodeURIComponent(data[i]));
				}
			}else if (data !== null) {
				data = unescape(encodeURIComponent(data));
			}
		}

		return data;
	}

	,decode_utf8(data) {
		if (data !== null) {
			if (typeof data == 'object') {
				let keys = Object.keys(data);

				for(let i=0; i<keys.length; i++) {
					console.log(keys[i], data[keys[i]]);
					if (typeof data[keys[i]] == 'object' && data[keys[i]] !== null)
						data[keys[i]] = this.decode_utf8(data[keys[i]]);
					else if (Array.isArray(data[keys[i]]) && data[keys[i]] !== null)
						data[keys[i]] = this.decode_utf8(data[keys[i]]);
					else if (data[keys[i]] !== null)
						data[keys[i]] = decodeURIComponent(escape(data[keys[i]]));
				}
			}else if (Array.isArray(data)) {
				for(let i=0; i<data.length; i++) {
					if (typeof data[i] == 'object' && data[keys[i]] !== null)
						data[i] = this.decode_utf8(data[i]);
					else if (Array.isArray(data[keys[i]]) && data[keys[i]] !== null)
						data[i] = this.decode_utf8(data[i]);
					else if (data[i] !== null)
						data[i] = decodeURIComponent(escape(data[i]));
				}
			}else if (data !== null) {
				data = decodeURIComponent(escape(data));
			}
		}

		return data;
	},

	descargar_documento(blob, nombre_original) {
		if (typeof nombre_original == 'undefined')
			throw new Error('Error al descargar archivo, no se definio el nombre');

		let url = window.URL.createObjectURL(new Blob([blob]));
		let link = document.createElement('a');
		link.href = url;
		link.setAttribute('download',nombre_original);
		document.body.appendChild(link);
		link.click();
	},

	diff_human: function(fecha_inicio, fecha_fin, dias_laborales) {
		let timestamp_inicio = typeof fecha_inicio != 'number' ? Vue.prototype.$moment(fecha_inicio).unix() : fecha_inicio;
		let timestamp_siguiente = fecha_fin != 'now' ? (typeof fecha_fin != 'number' ? Vue.prototype.$moment(fecha_fin).unix() : fecha_fin) : Vue.prototype.$moment().unix();
		let segundos = typeof fecha_fin != 'undefined' ? (timestamp_siguiente - timestamp_inicio) : fecha_inicio;
		
		if (typeof dias_laborales != 'boolean')
			dias_laborales = false;

		if (dias_laborales) {
			let fecha_inicio_org = Vue.prototype.$moment(fecha_inicio);
			let inicio_cero = Vue.prototype.$moment(fecha_inicio_org.format('YYYY-MM-DD')+'T00:00:00');
			let hoy_horas = Vue.prototype.$moment();
			let segundos_diferencias = hoy_horas.unix() - inicio_cero.unix();
			let dias_diferencias = Math.floor(segundos_diferencias / 86400);

			let dias_invalidos = 0;
			for(let i=1; i<=dias_diferencias; i++) {

				if ([0,6].indexOf(parseInt(inicio_cero.format('d'))) != -1)
					dias_invalidos++;

				inicio_cero.add(1,'day');
			}

			if (dias_diferencias > dias_invalidos) {
				let segundos_invalidos = dias_invalidos * 86400;
				segundos -=segundos_invalidos;
			}
		}

		let mensajes = '';

		if (segundos > 31556926) {
			let anios = Math.floor(segundos / 31556926);
			segundos = segundos - (anios * 31556926);
			mensajes += (mensajes != '' ? ', ' : '')+anios+' año(s)';
		}

		if (segundos > 2629744) {
			let meses = Math.floor(segundos / 2629744);
			segundos = segundos - (meses * 2629744);
			mensajes += (mensajes != '' ? ', ' : '')+meses+' mes(es)';
		}

		if (segundos > 604800) {
			let semanas = Math.floor(segundos / 604800);
			segundos = segundos - (semanas * 604800);
			mensajes += (mensajes != '' ? ', ' : '')+semanas+' semana(s)';
		}

		if (segundos > 86400) {
			let dias = Math.floor(segundos / 86400);
			segundos = segundos - (dias * 86400);
			mensajes += (mensajes != '' ? ', ' : '')+dias+ ' día(s)';
		}

		if (segundos > 3600) {
			let horas = Math.floor(segundos / 3600);
			segundos = segundos - (horas * 3600);
			if (mensajes == '') {
				mensajes = horas+' hora(s)';
			}else {
				mensajes += ', '+horas+' hora(s)';
			}
		}

		if (segundos > 60) {
			let minutos = Math.floor(segundos / 60);
			segundos = segundos - (minutos * 60);
			if (mensajes == ''){
				mensajes = minutos+' minuto(s)';
			}else {
				mensajes += ', '+minutos+' minuto(s)';
			}
		}

		if (segundos > 0) {
			if (mensajes == '')
				mensajes = segundos+' segundo(s)';
			else
				mensajes += ' y '+segundos+' segundo(s)';
		}

		return mensajes;
	},

	nl2br: function(str) {
		// console.log('nl2br', str);

		if (typeof str != 'string')
			str = String(str);

		let str2 = str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1<br>$2");

		return str2;
	},
	
	lazyload(module) {
		return() => import(`@/apps/${module}`);
	}
}